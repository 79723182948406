@mixin sp {
  @media (max-width: ($breakpoint_sp)) {
    @content;
  }
}
@mixin pc {
  @media (min-width: ($breakpoint_pc)) {
    @content;
  }
}

@mixin absolute-centering($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin text-truncate {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin responsive-iframe($ratio:56.25%){
  position: relative;
  width: 100%;
  padding-top: $ratio;
  & iframe{
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

@mixin en-font() {
  font-family: 'Oswald', Helvetica, Arial, sans-serif;
  font-weight: 600;
}

@mixin default-font() {
  font-family: Arial,Roboto,"Droid Sans","游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
}