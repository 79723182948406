.global-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #f3f3f3;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 5000;

  &--top {
    background-color: transparent;
    position: fixed;
    border-bottom-color: transparent;
  }

  &--top & {
    &__logo {
      visibility: hidden;
    }

    &__menu-trigger {
      &-bg {
        fill: transparent;
      }

      &-bar {
        fill: #000717;
      }
    }
  }

  &--top.is-active {
    background-color: rgba(255, 255, 255, 0.95);
    border-bottom-color: #f3f3f3;
  }

  &--top.is-active & {
    &__logo {
      visibility: visible;
    }

    &__menu a {
      color: $color_black;

      &:hover {
        .stop1 {
          stop-color: #4f20ff;
        }

        .stop2 {
          stop-color: #f7385a;
        }
      }
    }

    &__menu-trigger {
      &-bg {
        fill: #000717;
      }

      &-bar {
        fill: #fff;
      }
    }
  }

  &__logo {
    img {
      @include sp() {
        width: 180px;
        height: auto;
      }
    }
  }

  &__body {
    width: 920px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include sp() {
      width: 100%;
      height: 55px;
      padding-left: 15px;
    }
  }

  &__menu-trigger {
    @include pc() {
      display: none;
    }

    svg {
      vertical-align: top;
    }
  }

  &__menu {
    display: flex;
    align-items: center;

    @include sp() {
      display: none;
    }

    li {
      line-height: 1;
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }

      &:nth-last-child(2) {
        margin-left: 30px;
      }

      /*
      snsアイコン非表示のため
      &:last-child {
        margin-left: 13px;
      }
      */
    }

    a {
      @include en-font();
      text-decoration: none;
      color: $color_black;
      font-size: 18px;

      &:hover {
        // color: $color_primary;
        background: linear-gradient(268.08deg, #4f20ff 0%, #f7385a 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::selection {
          -webkit-text-fill-color: #fff;
        }
      }

      // &:hover path {
      //   fill: $color_primary;
      // }
      &:hover {
        .stop1 {
          stop-color: #4f20ff;
        }

        .stop2 {
          stop-color: #f7385a;
        }
      }
    }
  }

  &__twitter {
    fill: url(#Gradient1);
  }

  &__facebook {
    fill: url(#Gradient2);
  }
}

.sp-navi {
  position: fixed;
  top: 0;
  right: 0;
  width: 295px;
  height: 100%;
  overflow: scroll;
  background-color: $color_black;
  transform: translateX(100%);
  transition: transform 250ms ease;
  z-index: 5001;

  @include pc() {
    display: none;
  }

  &.is-active {
    transform: translateX(0);
  }

  &__menu-trigger {
    display: block;
    margin-left: auto;
  }

  &__body {
    padding: 0 20px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 40px 0;

    a {
      line-height: 1;
      @include en-font();
      font-size: 24px;
      text-decoration: none;
      color: #fff;
    }
  }

  &__sub-menu {
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    border-top: 1px solid #242a37;
    padding-top: 35px;
    margin-top: 35px;

    a {
      line-height: 1;
      font-size: 14px;
      text-decoration: none;
      color: #fff;
    }
  }

  &__sns {
    margin-top: 30px;
    display: flex;
    gap: 0 10px;
  }
}

.contact-footer {
  background-image: url(../img/common/bg_contact.jpg);
  background-size: cover;
  padding: 130px 0 100px;

  @include sp() {
    padding: 60px 15px 40px;
  }

  &__body {
    width: 980px;
    margin: 0 auto;

    @include sp() {
      width: 100%;
    }
  }

  &__title {
    font-size: 120px;
    line-height: 1;
    color: #fff;
    @include en-font();

    @include sp() {
      font-size: 64px;
    }
  }

  &__lead {
    width: 920px;
    margin: 45px auto;
    font-size: 20px;
    color: #fff;

    @include sp() {
      width: 90%;
      font-size: 16px;
      margin: 20px auto 30px;
    }
  }

  &__button {
    width: 400px;
    margin: 0 auto;

    @include sp() {
      width: 290px;
    }
  }
}

.global-footer {
  background-color: $color_black;

  &__body {
    width: 980px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    @include sp() {
      width: 100%;
      height: auto;
      padding: 15px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px 0;
    }
  }

  &__link {
    display: flex;
    gap: 0 20px;

    @include sp() {
      gap: 0 10px;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    i {
      font-size: 10px;
      color: #ccc;
    }
  }

  &__copyright {
    color: #999;
    font-size: 12px;
    @include en-font();

    @include sp() {
      font-size: 11px;
    }
  }
}

.basic-button {
  display: block;
  width: 100%;
  text-align: center;
  // background-color: $color_black;
  position: relative;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;

  background: linear-gradient(90deg,
      #f7385a,
      #4f20ff 50%,
      $color_black 50%,
      $color_black );
  background-position: 100% 0;
  background-size: 201% 100%;
  transition: background-position 0.4s cubic-bezier(0.25, 1, 0.5, 1);

  &:hover {
    @include pc() {
      background-position: -1px 0;
    }
  }

  @include sp() {
    padding: 9px;
    font-size: 14px;
    width: 80%;
    margin: 0 auto;
  }

  &--sp-color-change {
    @include sp() {
      background: #fff;
      color: $color_black;
      border: solid 1px $color_black;
      max-height: 40.4px;
    }
  }

  &--sp-full-width {
    @include sp() {
      width: 100%;
    }
  }

  &--large {
    font-size: 20px;
    padding: 24px;

    @include sp() {
      // font-size: 18px;
      // padding: 15px;
      padding: 9px;
      font-size: 14px;
    }
  }

  &--x-large {
    font-size: 24px;
    padding: 20px;

    @include sp() {
      // padding: 15px;
      padding: 9px;
      font-size: 14px;
    }
  }

  &:after {
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 14px;
  }

  &--next:after {
    content: "\f054";
  }

  &--down:after {
    content: "\f078";
  }

  &--external:after {
    content: "\f24d";
    font-weight: 400;
  }

  &--large:after {
    font-size: 16px;
  }

  &--x-large:after {
    font-size: 18px;

    @include sp() {
      font-size: 16px;
    }
  }

  &--skeleton {
    border: 1px solid #fff;
    background-color: transparent;
    max-height: 80px;

    @include sp() {
      max-height: 40.4px;
    }

    &:hover {
      @include pc() {
        border-color: transparent;
      }
    }
  }

  &--white {
    color: $color_black;
    // background-color: #fff;
    background: linear-gradient(90deg, #f7385a, #4f20ff 50%, #fff 50%, #fff);
    background-position: 100% 0;
    background-size: 201% 100%;

    &:hover {
      @include pc() {
        color: #fff;
      }
    }
  }

  &--min-pcH80 {
    @include pc() {
      min-height: 80px;
    }
  }

  &--disabled {
    cursor: default;
    background-position: 0 0;
    background: #999;

    &:hover {
      @include pc() {
        color: #fff;
        background: #999;
      }
    }
  }
}

.breadcrumbs {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;

  @include sp() {
    margin: 20px 0;
  }

  li {
    font-weight: bold;

    &:after {
      font-family: "Font Awesome 5 Free";
      content: "\f054";
      vertical-align: baseline;
      margin: 0 5px;
      font-size: 10px;
      color: #ccc;
    }

    &:last-child:after {
      display: none;
    }
  }

  a {
    color: $color_black;
    text-decoration: none;
    font-weight: normal;

    &:hover {
      @include pc() {
        text-decoration: underline;
      }
    }
  }
}

.contents-wrapper {
  width: 920px;
  margin: 0 auto;

  @include sp() {
    width: 100%;
    padding: 0 15px;
  }

  &--maintenance {
    @include pc() {
      height: calc(100% - 180px);
      min-height: calc(100vh - 180px);
    }

    @include sp() {
      height: calc(100% - 128px);
      min-height: calc(100vh - 128px);
    }
  }
}

.contents-header {
  margin-bottom: 80px;

  @include sp() {
    margin-bottom: 60px;
  }

  &__title {
    font-size: 60px;
    line-height: 1;
    @include en-font();

    @include sp() {
      font-size: 32px;
    }

    span {
      @include default-font();
      font-weight: bold;
      margin-left: 40px;
      font-size: 18px;
      position: relative;
      padding-left: 30px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 24px;
        height: 1px;
        background-color: $color_black;
      }

      @include sp() {
        font-size: 12px;
        margin-left: 15px;
      }
    }
  }
}

.list-anchor-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  @include sp() {
    margin-bottom: 30px;
  }

  li {
    width: 31%;
  }

  &--2item {
    justify-content: center;
    gap: 0 30px;

    @include sp() {
      gap: 0 2%;
    }

    li {
      width: 350px;

      @include sp() {
        width: 49%;
      }
    }
  }
}

.border-title {
  @include en-font();
  font-size: 32px;
  line-height: 1;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 30px;
  padding-bottom: 15px;

  @include sp() {
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  &--white {
    color: #f3f3f3;
  }
}

.works-card {
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border: 1px solid #ddd;
  background-color: #fff;
  visibility: hidden;
  opacity: 1;
  transform: translateY(20px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;

  &.swiper-slide {
    height: auto;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }

  &--borderless {
    border: 0;
  }

  @include sp() {
    display: block;
  }

  &--sp-layout {
    display: flex;
    flex-direction: column;

    @include sp() {
      display: flex;
    }
  }

  &--sp-layout &__thumbnail {
    width: 100%;
    height: unset;
    padding-top: 50%;

    &-hover-image {
      animation: cardFadeAnimation 6s ease infinite;
    }
  }

  &--sp-layout &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &-button {
      width: 80%;
      display: block;
      margin-top: auto;
      padding-top: 15px;
    }

    &-lead {
      display: block;
    }
  }

  &--sp-layout &__sub-title {
    @include sp() {
      margin-top: 15px;
      font-size: 14px;
    }
  }

  &+& {
    margin-top: 30px;
  }

  &--closed &__thumbnail {
    &:after {
      backdrop-filter: blur(5px);
    }
  }

  &:not(&--sp-layout):hover & {
    @include pc() {
      &__thumbnail {

        &:after,
        &-hover-image,
        &-box {
          opacity: 1;
        }
      }
    }
  }

  &__thumbnail {
    width: 65.2%;
    height: 268px;
    position: relative;

    @include sp() {
      width: 100%;
      height: unset;
      padding-top: 50%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // background: rgba(34, 131, 188, 0.7);
      background: linear-gradient(268.08deg,
          rgba(79, 32, 255, 0.7) 0%,
          rgba(247, 56, 90, 0.7) 100%);
      z-index: 10;
      opacity: 0;
      transition: opacity 250ms ease;
    }

    &-image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &-hover-image {
      opacity: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 250ms ease;

      @include sp() {
        animation: cardFadeAnimation 6s ease infinite;
      }
    }

    &-box {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      transition: opacity 250ms ease;
      z-index: 11;
    }

    &-button {
      width: 240px;
      margin: 0 auto;
    }

    &-lead {
      font-size: 18px;
      color: #fff;
    }

    &-lead+&-button {
      margin-top: 15px;
    }
  }

  &__body {
    width: 34.8%;
    padding: 15px;
    text-align: center;

    @include sp() {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &-button {
      width: 80%;
      display: none;
      margin-top: 15px;

      @include sp() {
        width: 290px;
        display: block;
      }
    }

    &-lead {
      font-size: 14px;
      width: 100%;
      margin-top: 15px;
      display: none;

      @include sp() {
        display: block;
      }
    }
  }

  &__logo {
    max-width: 100%;
    height: auto;
  }

  &__sub-title {
    margin-top: 25px;
    font-size: 18px;
    font-weight: bold;

    @include sp() {
      margin-top: 15px;
      font-size: 14px;
    }
  }
}

.service-slide {
  width: 286px;
  visibility: visible;
}

.service-slide-wrapper {
  padding-bottom: 70px;
}

.service-slide-wrapper+.swiper-pagination>.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #000717;
}

.top-product-slide-wrapper {
  padding-bottom: 50px;
}

.top-product-slide-wrapper+.swiper-pagination>.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
}

.top-product-slide-wrapper+.swiper-pagination>.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}

@keyframes cardFadeAnimation {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.three-column-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 3.5%;

  @include sp() {
    gap: 15px 2%;
  }

  &__item {
    width: 31%;

    @include sp() {
      width: 49%;
    }
  }
}

.member-card {
  text-decoration: none;
  $block: &;

  &__info {
    position: relative;
    padding-right: 26px;

    @include sp() {
      padding-right: 22px;
    }

    &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
      border-radius: 4px;
      font-family: "Font Awesome 5 Free";
      content: "\f054";
      font-weight: 900;
      text-align: center;
      font-size: 12px;
      line-height: 1.9;
      // border: solid 1px #fff;
      color: #fff;
      // background-color: $color_black;
      // transition: background-color 250ms ease, border-color 250ms ease;
      transition: background-position 0.4s cubic-bezier(0.25, 1, 0.5, 1);
      background: linear-gradient(90deg,
          #f7385a,
          #4f20ff 50%,
          $color_black 50%,
          $color_black );
      background-position: 100% 0;
      background-size: 220% 100%;

      @include sp() {
        width: 18px;
        height: 18px;
        font-size: 10px;
      }
    }

    &__name {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;

      @include sp() {
        font-size: 16px;
        display: flex;
        flex-direction: column;
      }

      span {
        @include en-font();
        font-size: 12px;
        color: #ddd;
        margin-left: 15px;

        @include sp() {
          font-size: 10px;
          margin: 0 0 5px;
        }
      }
    }

    &__position {
      color: $color_primary;
      font-weight: bold;
    }
  }

  &:hover {
    #{$block}__info {
      &:after {
        @include pc() {
          // background-color: $color_primary;
          // border-color: $color_primary;
          background-position: -1px 0;
        }
      }
    }
  }
}

.banner-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 32px;

  @include sp() {
    gap: 15px 2%;
  }

  &__item {
    width: 206px;

    @include sp() {
      width: 49%;
    }
  }
}

.interview-title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;

  @include sp() {
    font-size: 20px;
    margin-bottom: 15px;
  }

  span {
    display: inline-block;
    position: relative;
    padding-right: 35px;
    color: $color_primary;
    @include en-font();

    @include sp() {
      font-size: 12px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 24px;
      height: 1px;
      background-color: $color_primary;
    }
  }
}

.interview-paragraph {
  & p {
    font-size: 16px;
    line-height: 1.8;

    &:nth-of-type(n + 2) {
      margin-top: 2em;
    }

    @include sp() {
      font-size: 14px;
    }
  }

  &--line {
    border: 1px solid #ddd;
    padding: 30px;
    position: relative;

    @include sp() {
      padding: 30px 15px;
    }

    &:before {
      font-family: "Font Awesome 5 Free";
      content: "\f10d";
      font-weight: 900;
      position: absolute;
      top: -10px;
      left: 20px;
      font-size: 14px;
      color: #ddd;
    }

    &:after {
      font-family: "Font Awesome 5 Free";
      content: "\f10e";
      font-weight: 900;
      position: absolute;
      bottom: -10px;
      right: 20px;
      font-size: 14px;
      color: #ddd;
    }
  }

  &--text-only {
    & p {
      font-size: 16px;
      line-height: 1.8;

      &:nth-of-type(n + 2) {
        margin-top: 2em;
      }

      @include sp() {
        font-size: 14px;
      }
    }
  }

  &__box {
    display: flex;
    flex-direction: column;

    &__icon-contents {
      order: 1;
    }

    &__image-contents {
      order: 2;

      & p {
        font-size: 16px;
        line-height: 1.8;

        &:nth-of-type(n + 2) {
          margin-top: 2em;
        }

        @include sp() {
          font-size: 14px;
        }
      }
    }

    &__image-position {
      max-width: 430px;
      height: auto;

      @include sp() {
        max-width: unset;
        width: 100%;
        height: auto;
        float: none;
        margin: 0 0 10px 0;
      }

      &--right {
        float: right;
        margin-left: 20px;
      }

      &--left {
        float: left;
        margin-right: 20px;
      }
    }

    &__icon-contents {
      display: flex;
      align-items: center;
    }

    &__icon-frame {
      position: relative;
      overflow: hidden;
      width: 70px;
      height: 70px;
      border-radius: 50%;

      @include sp() {
        width: 60px;
        height: 60px;
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      min-width: 105px;

      @include sp() {
        min-width: 95px;
      }
    }

    &__icon-name {
      margin-left: 15px;
      color: $color_primary;
      font-size: 20px;
      font-weight: bold;
    }
  }

  &__left-image {
    max-width: 430px;
    height: auto;
    float: left;
    margin-right: 20px;

    @include sp() {
      max-width: unset;
      width: 100%;
      float: none;
      margin: 0 0 10px 0;
    }
  }

  &__right-image {
    max-width: 430px;
    height: auto;
    float: right;
    margin-left: 20px;

    @include sp() {
      max-width: unset;
      width: 100%;
      height: auto;
      float: none;
      margin: 0 0 10px 0;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.8;

    @include sp() {
      font-size: 14px;
    }
  }
}

.interview-section {
  &+& {
    margin-top: 80px;

    @include sp() {
      margin-top: 40px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 40px 0;

    @include sp() {
      gap: 25px 0;
    }
  }
}

.interview-button {
  width: 400px;
  margin: 60px auto 0;

  @include sp() {
    margin-top: 30px;
    width: 290px;
  }
}

.interview-anchor {
  display: flex;
  position: relative;
  text-decoration: none;
  background-color: #f3f3f3;
  padding: 14px 80px 14px 40px;
  border-radius: 4px;
  overflow: hidden;
  color: $color_black;
  align-items: center;

  @include sp() {
    padding: 14px 25px 14px 10px;
  }

  @include pc() {
    &:hover:before {
      // transform: scale(1, 1);
      background-position: 100% 0;
    }

    &:hover:after {
      color: #fff;
    }

    &:hover & {

      &__title,
      &__number {
        color: #fff;
      }
    }
  }

  &+& {
    margin-top: 10px;
  }

  &__number {
    width: 100px;
    line-height: 1;
    color: $color_primary;
    @include en-font();
    font-size: 16px;
    position: relative;
    transition: color 250ms ease;
    pointer-events: none;

    @include sp() {
      font-size: 12px;
      width: 80px;
    }
  }

  &__title {
    width: calc(100% - 100px);
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    position: relative;
    transition: color 250ms ease;
    pointer-events: none;

    @include sp() {
      font-size: 14px;
      width: calc(100% - 80px);
    }
  }

  &:before {
    font-size: 20px;
    content: "";
    width: 100%;
    height: 100%;
    // background-color: $color_black;
    // background: linear-gradient(90deg, #F7385A, #4F20FF 50%, #f3f3f3 50%, #f3f3f3);
    background: linear-gradient(90deg,
        #f3f3f3,
        #f3f3f3 50%,
        #f7385a 50%,
        #4f20ff);
    background-position: 0 0;
    background-size: 220% 100%;
    // transform: scale(0, 1);
    // transform-origin: center right;
    position: absolute;
    top: 0;
    right: 0;
    // transition: transform 250ms ease;
    transition: background-position 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  }

  &:after {
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    font-weight: 900;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    font-size: 14px;
    transition: color 250ms ease;
    color: $color_black;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sp() {
      width: 15px;
      font-size: 10px;
      right: 10px;
    }
  }
}

.detail-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;

  @include sp() {
    padding: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.news-section {
  background: #f8f8f8;
  padding: 100px 0;

  @include sp() {
    padding: 70px 0;
  }

  &__title {
    width: 1040px;
    margin: 0 auto 40px;
    color: #fff;
    @include en-font();
    font-size: 120px;
    line-height: 1;

    @include sp() {
      width: 100%;
      padding: 0 15px;
      font-size: 96px;
    }
  }
}

.news-item {
  &__date {
    @include en-font();
    line-height: 1;
    font-size: 20px;
    margin-bottom: 20px;

    @include sp() {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  &__title {
    display: block;
    color: $color_black;
    line-height: 1.3;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;

    &:hover {
      @include pc() {
        // color: $color_primary;
        background: linear-gradient(268.08deg, #4f20ff 0%, #f7385a 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    @include sp() {
      font-size: 16px;
    }
  }

  &__text {
    font-size: 16px;
    color: #666;

    @include sp() {
      font-size: 14px;
    }
  }

  &+& {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #ddd;

    @include sp() {
      padding-top: 15px;
      margin-top: 15px;
    }
  }
}

.basic-section-text {
  font-size: 18px;

  @include sp() {
    font-size: 15px;
  }
}

.profile-modal {
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 768px;
  margin: 0 auto;
  padding: 80px 100px 60px;
  position: relative;

  @include sp() {
    width: 100%;
    padding: 50px 15px 30px;
  }

  &__image {
    width: 400px;
    height: auto;

    @include sp() {
      max-width: 100%;
    }
  }

  &__name-en {
    font-size: 14px;
    @include en-font();
    color: #ddd;
    margin-top: 20px;
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
  }

  &__position {
    color: $color_primary;
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px;
  }

  &__text {
    margin-top: 25px;

    @include sp() {
      margin-top: 15px;
    }
  }

  .mfp-close {
    font-size: 40px;
  }
}

.mfp-content .profile-modal {
  display: flex;
}

.news-card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @include sp() {
    flex-direction: row;
    gap: 30px 0;
  }

  &__item {
    width: 430px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f3f3f3;

    @include sp() {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    padding-bottom: 10px;
  }
}

.news-card {
  &__tag {
    color: #999;
    background-color: #f3f3f3;
    text-decoration: none;
    display: inline-block;
    padding: 7px 10px;
    margin-bottom: 10px;
    line-height: 1.2;
    font-weight: bold;
    border-radius: 2px;
  }

  &--white & {

    &__date,
    &__link,
    &__no-link,
    &__lead {
      color: #fff;
    }

    &__tag {
      color: #fff;
      padding: 3px 5px;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  &__date {
    @include en-font();
    font-size: 18px;
  }

  &__link {
    display: block;
    font-weight: bold;
    font-size: 17px;
    color: $color_black;
    line-height: 1.3;
    margin: 10px 0;

    &:hover {
      @include pc() {
        // color: $color_primary;
        background: linear-gradient(268.08deg, #4f20ff 0%, #f7385a 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::selection {
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }

  &__no-link {
    display: block;
    font-weight: bold;
    font-size: 17px;
    color: $color_black;
    line-height: 1.3;
    margin: 10px 0;
  }

  &__lead {
    font-size: 15px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__controller {
    width: 40px;
    height: 40px;
    // background-color: $color_black;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    // transition: background-color 250ms ease;
    transition: background-position 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    background: linear-gradient(90deg,
        #f7385a,
        #4f20ff 50%,
        $color_black 50%,
        $color_black );
    background-position: 100% 0;
    background-size: 220% 100%;

    &.is-hidden {
      visibility: hidden;
    }

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }

    i {
      font-size: 16px;
    }

    &:hover {
      @include pc() {
        // background-color: $color_primary;
        background-position: -1px 0;
      }
    }
  }

  &__link {
    font-size: 18px;
    color: #999;
    text-decoration: none;
    font-weight: bold;
    margin: 0 10px;
    display: flex;
    align-items: center;

    // transition: color 250ms ease;
    @at-root a#{&}:hover {
      @include pc() {
        // color: $color_primary;
        background: linear-gradient(268.08deg, #4f20ff 0%, #f7385a 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::selection {
          -webkit-text-fill-color: #fff;
        }
      }
    }

    &.is-current {
      color: $color_black;
    }
  }
}

.top-main-visual {
  position: relative;
  height: 100vh;
  width: 100%;
  background: url(../img/top/bg_main-visual.png) no-repeat center / cover;

  @include sp() {
    height: 80vh;
  }

  &.is-show &__video {
    opacity: 1;
    visibility: visible;
  }

  &.is-thin &__video {
    opacity: 0.5;
    visibility: visible;
  }

  &__video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s ease, visibility 1s ease;
    z-index: 100;

    video {
      position: absolute;
      min-width: 100%;
      min-height: 100vh;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);

      @include sp() {
        min-width: unset;
        max-width: 100%;
      }
    }
  }

  &__lead {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;

    @include sp() {
      width: 100%;
      top: 45%;
      left: 0;
      transform: translate(0, -50%);
      text-indent: 5%;
    }

    img {
      @include sp() {
        width: 90%;
        height: auto;
      }
    }
  }

  &__scroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @include en-font();
    font-size: 16px;
    color: $color_black;
    padding-bottom: 60px;
    line-height: 1;
    user-select: none;
    z-index: 101;

    &:after {
      content: "";
      background-color: $color_black;
      position: absolute;
      width: 2px;
      height: 50px;
      left: calc(50% - 1px);
      bottom: 0;
      transform-origin: top bottom;
      animation: scrollBarAnimation 2.5s cubic-bezier(1, 0, 0, 1) infinite;
    }
  }
}

/* コンテナを画面いっぱいに設定 */
.top-main-event-visual {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

/* 画像がコンテナいっぱいに表示されるように設定 */
.top-main-event-visual__lead img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 画像の縦横比を維持しつつ、コンテナ全体を覆うように表示 */
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes scrollBarAnimation {
  0% {
    transform-origin: 0% 0%;
    transform: scale(1, 0);
  }

  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }

  50.1% {
    transform-origin: 0% 100%;
    transform: scale(1, 1);
  }

  100% {
    transform-origin: 0% 100%;
    transform: scale(1, 0);
  }
}

.top-white-area {
  padding: 20px 0;
  position: relative;
  z-index: 500;

  @include sp() {
    padding: 20px 15px;
  }
}

.top-black-area {
  padding: 80px 0;
  background-color: #fff;
  transition: background-color 2s ease;

  @include sp() {
    padding: 30px 0 40px;
  }

  &.is-active {
    background-color: $color_black;

    .top-news-slider {
      &:after {
        width: 25%;
      }
    }
  }
}

.top-contents-box {
  width: 1040px;
  margin: 0 auto;

  @include sp() {
    width: 100%;
  }

  &+& {
    border-top: 1px solid #ddd;
    padding-top: 80px;
    margin-top: 80px;

    @include sp() {
      padding-top: 40px;
      margin-top: 40px;
    }
  }

  &+&--boredr {
    border-top: 0;
  }

  &--under-space {
    border-bottom: 1px solid #666;
    padding-bottom: 80px;
    margin-bottom: 80px;

    @include sp() {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }

  &__body {
    width: 920px;
    margin: 0 auto;

    &--center {
      margin: 0 auto;
    }

    @include sp() {
      width: 100%;
      padding: 0 15px;
    }
  }

  &__text {
    font-size: 16px;

    @include sp() {
      font-size: 14px;
    }
  }

  &__button {
    width: 400px;
    margin: 0px;

    @include sp() {
      width: 290px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    width: 100%;

    @include pc() {
      max-width: 920px;
    }
  }

  &__our-action-button {
    margin: 40px auto 0;

    @include pc() {
      max-width: 400px;
    }

    @include sp() {
      margin: 24px auto 0;
      max-width: 232px;
    }
  }

  &__video {
    height: auto;

    @include pc() {
      width: 1040px;
      margin: 40px auto;
    }

    @include sp() {
      width: 96%;
      margin: 24px auto;
    }
  }

  &__view_all_button {
    margin: 40px auto 0;

    @include pc() {
      max-width: 400px;
    }

    @include sp() {
      margin: 24px auto 0;
      max-width: 232px;
    }
  }
}

.main-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.top-two-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

@include sp() {
  .top-two-buttons {
    flex-direction: column;
  }
}

.top-catchword {
  position: relative;
  width: 980px;
  margin-left: auto;
  padding-top: 30px;

  @include sp() {
    width: 100%;
    padding-top: 40px;
  }

  &+& {
    margin-top: 100px;

    @include sp() {
      margin-top: 80px;
    }
  }

  &__en-title {
    line-height: 1;
    @include en-font();
    position: absolute;
    top: -15px;
    right: 0;
    color: #ddd;
    font-size: 128px;
    letter-spacing: 0.03em;

    @include sp() {
      top: -10px;
      font-size: 96px;
      right: -15px;
    }
  }

  &__title {
    position: relative;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.2em;
    margin-bottom: 25px;
    line-height: 1.2;

    @include sp() {
      font-size: 22px;
      margin-bottom: 15px;
    }
  }

  &__lead {
    position: relative;
    font-size: 20px;
    letter-spacing: 0.05em;

    @include sp() {
      font-size: 14px;
    }
  }
}

.top-en-title {
  line-height: 1;
  @include en-font();
  font-size: 100px;
  letter-spacing: 0.03em;
  color: #ddd;

  @include sp() {
    font-size: 68px;
  }
}

.top-result-data {
  display: flex;
  margin-top: 50px;

  @include sp() {
    flex-direction: column;
    gap: 20px 0;
    margin-top: 30px;
  }

  &__item {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include sp() {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }
  }

  &__name {
    font-weight: bold;
    font-size: 18px;

    @include sp() {
      font-size: 16px;
    }
  }

  &__number {
    font-weight: bold;
    font-size: 20px;

    @include sp() {
      font-size: 16px;
    }

    span {
      @include en-font();
      font-size: 72px;
      // color: $color_primary;
      background: linear-gradient(45deg, #f7385a 0%, #4f20ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 5px;
      line-height: 1;

      &::selection {
        -webkit-text-fill-color: #fff;
      }

      &:hover {
        &::selection {
          -webkit-text-fill-color: #fff;
        }
      }

      @include sp() {
        font-size: 58px;
      }
    }
  }
}

.top-result-banner-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-top: 70px;

  @include sp() {
    gap: 10px 2%;
    margin-top: 50px;
  }

  li {
    width: 215px;

    @include sp() {
      width: 49%;
    }
  }
}

.top-result-update {
  text-align: right;
  color: #999;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;

  @include sp() {
    margin-top: 10px;
    font-size: 11px;
  }
}

.top-news {
  padding-left: calc(calc(100% - 1040px) / 2);

  @include sp() {
    padding-left: 15px;
  }

  &__header {
    display: flex;
    margin-bottom: 30px;

    &-controller {
      display: flex;
      margin-left: 450px;

      @include sp() {
        margin-left: 50px;
        justify-content: center;
      }

      &-button {
        width: 40px;
        height: 40px;
        border: 1px solid #fff;
        border-radius: 4px;
        color: #fff;
        // transition: background-color 250ms ease, border-color 250ms ease;
        align-self: flex-end;
        transition: background-position 0.4s cubic-bezier(0.25, 1, 0.5, 1);
        background: linear-gradient(90deg,
            #f7385a,
            #4f20ff 50%,
            $color_black 50%,
            $color_black );
        background-position: 100% 0;
        background-size: 220% 100%;

        &:hover {
          @include pc() {
            // background-color: $color_primary;
            // border-color: $color_primary;
            border-color: transparent;
            background-position: -1px 0;
          }
        }

        &+& {
          margin-left: 15px;
        }

        i {
          font-size: 18px;
        }
      }
    }
  }
}

.top-news-slider {
  padding-left: 60px;

  @include sp() {
    padding-left: 0;
  }

  &__slide {
    width: 420px;
    padding-right: 60px;

    @include sp() {
      width: 305px;
      padding-right: 30px;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(270deg,
        rgba(0, 7, 23, 1) 0%,
        rgba(0, 7, 23, 0.8001794467787114) 80%,
        rgba(255, 255, 255, 0) 100%);
    z-index: 2;
    transition: width 1s ease;
    transition-delay: 1.5s;

    @include sp() {
      content: none;
    }
  }
}

.pagetop-button {
  position: fixed;
  bottom: 15px;
  right: 100px;
  z-index: 5000;
  opacity: 0;
  transition: opacity 250ms ease;

  @include sp() {
    right: 15px;
  }

  img {
    @include sp() {
      width: 64px;
      height: auto;
    }
  }

  &.is-active {
    opacity: 1;
  }
}

.news-selector {
  width: 290px;
  margin: 0 0 40px auto;
  position: relative;

  @include sp() {
    width: 110px;
  }

  &__display {
    width: 100%;
    @include en-font();
    font-size: 24px;
    line-height: 1;
    padding: 20px;
    color: #fff;
    background: $color_black;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    user-select: none;

    @include sp() {
      font-size: 14px;
      padding: 15px;
    }

    &:after {
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      font-size: 18px;

      @include sp() {
        right: 10px;
        font-size: 14px;
      }
    }
  }

  &__list {
    width: 100%;
    background: $color_black;
    border-radius: 4px;
    position: absolute;
    top: 66px;
    left: 0;
    padding: 5px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: visibility 250ms ease, opacity 250ms ease, transform 250ms ease;

    @include sp() {
      top: 46px;
      padding: 2px;
    }

    &.is-shown {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    li+li {
      border-top: 1px solid rgba(221, 221, 221, 0.2);
    }

    a {
      padding: 18px;
      display: block;
      @include en-font();
      color: #fff;
      font-size: 24px;
      line-height: 1;
      text-decoration: none;
      // transition: color 250ms ease, background 250ms ease;
      user-select: none;
      background: linear-gradient(90deg,
          #f7385a,
          #4f20ff 50%,
          $color_black 50%,
          $color_black );
      background-position: 100% 0;
      background-size: 201% 100%;
      transition: background-position 0.4s cubic-bezier(0.25, 1, 0.5, 1);

      @include sp() {
        font-size: 14px;
        padding: 15px 10px;
      }

      &:hover {
        @include pc() {
          border-radius: 4px;
          // background: $color_primary;
          color: #fff;
          background-position: -1px 0;
        }
      }
    }
  }
}

.top-opening {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: #fff;
  overflow: hidden;

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    i {
      font-size: 60px;
      color: #ddd;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.our-action-section {
  &:nth-of-type(n + 2) {
    margin-top: 120px;

    @include sp() {
      margin-top: 60px;
    }
  }

  &--back-color {
    width: 920px;
    margin: 0 auto;

    @include sp() {
      width: 100%;
      padding: 0 15px;
    }
  }
}

.our-action-gray {
  background: #f3f3f3;
}

.our-action-catchword {
  position: relative;
  padding-bottom: 120px;

  @include sp() {
    padding-bottom: 60px;
  }

  &--btn {
    padding-bottom: 150px;
  }

  &__title {
    position: relative;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: 1.8;
    top: 60px;
    left: 20px;

    @include sp() {
      font-size: 20px;
      line-height: 1.4;
      top: 30px;
      left: 10px;
    }
  }

  &__en-title {
    line-height: 1;
    @include en-font();
    position: absolute;
    top: 0;
    left: 0;
    color: #ddd;
    font-size: 80px;
    letter-spacing: 0.03em;

    @include sp() {
      font-size: 48px;
    }
  }

  &__btn-contents {
    position: relative;
    display: flex;
    top: 150px;

    @include pc() {
      justify-content: center;
    }

    @include sp() {
      top: 70px;
      justify-content: space-between;
    }

    & a {
      @include pc() {
        max-width: 350px;
      }

      @include sp() {
        width: 48%;
        margin: inherit;
      }

      &:last-of-type {
        @include pc() {
          margin-left: 30px;
        }
      }
    }
  }
}

.our-action-lead {
  font-size: 18px;
  line-height: 2.2;
  letter-spacing: 0.03em;

  @include sp() {
    font-size: 15px;
  }
}

.our-action-two-column {
  @include pc() {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__name {
    font-weight: bold;
    font-size: 22px;

    @include sp() {
      font-size: 18px;
    }
  }

  &__contents {
    @include pc() {
      width: 48%;
    }

    @include sp() {
      &:nth-of-type(n + 2) {
        margin-top: 30px;
      }
    }
  }

  &__post {
    color: $color_primary;
    font-size: 14px;
    font-weight: bold;

    @include sp() {
      font-size: 12px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 2;

    @include sp() {
      font-size: 13px;
    }
  }
}

.our-action-sub-section {
  &:nth-of-type(n + 2) {
    margin-top: 120px;
  }

  &__title {
    font-size: 32px;
    font-weight: bold;
    padding-left: 15px;
    border-left: solid 8px #ddd;

    @include sp() {
      font-size: 18px;
      padding-left: 10px;
      border-left: solid 5px #ddd;
    }
  }

  &__lead {
    font-weight: bold;
    margin-bottom: 40px;
    font-size: 18px;

    @include sp() {
      margin-bottom: 24px;
      font-size: 14px;
    }
  }

  &__more {
    background: #f3f3f3;
    padding: 40px;

    @include sp() {
      padding: 24px 14px;
    }
  }

  &__more-lead {
    color: #001e57;
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    @include sp() {
      font-size: 12px;
    }
  }

  &__more-title {
    font-weight: bold;
    font-size: 48px;
    text-align: center;
    @include en-font();

    @include sp() {
      font-size: 18px;
    }
  }

  &__more-text {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 40px;

    @include sp() {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }

  &__small-button {
    padding: 10px;
    font-size: 16px;
    width: 240px;

    @include sp() {
      width: 50%;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 30px;

    @include sp() {
      gap: 4%;
    }

    &-item {
      margin: 0;
      width: 350px;

      @include sp() {
        margin: 0;
        width: 48%;
      }
    }
  }
}

.our-action-btn {
  max-width: 400px;
  margin: 0 auto;

  @include sp() {
    max-width: 232px;
  }
}

.our-action-project {
  background: #fff;
  padding: 0 40px;

  @include sp() {
    padding: 0 15px;
  }

  &__list {
    padding: 30px 0;

    @include pc() {
      display: flex;
      justify-content: space-between;
    }

    @include sp() {
      padding: 20px 0;
    }

    &:nth-of-type(n + 2) {
      border-top: solid 1px #ddd;
    }
  }

  &__list-title {
    font-weight: bold;
    font-size: 18px;

    @include pc() {
      width: 23.5%;
    }

    @include sp() {
      margin-bottom: 15px;
    }
  }

  &__list-about {
    font-weight: bold;
    font-size: 18px;

    @include pc() {
      width: 74.5%;
    }

    @include sp() {
      font-size: 14px;
    }
  }
}

.contents-common-header {
  margin-bottom: 60px;

  @include sp() {
    margin-bottom: 40px;
  }

  &__title {
    @include en-font();
    font-size: 80px;
    font-weight: bold;
    color: #ddd;
    text-align: center;
    line-height: 1;

    @include sp() {
      font-size: 42px;
      line-height: 1.2;
    }

    &--size-adjustment {
      font-size: 72px;
    }
  }

  &__sub-title {
    font-size: 30px;
    font-weight: bold;
    display: inline-block;
    color: #000717;

    @include sp() {
      font-size: 24px;
    }
  }
}

.contents-common {
  &__name {
    font-weight: bold;
    font-size: 20px;

    @include sp() {
      font-size: 18px;
    }
  }

  &__post {
    color: $color_primary;
    font-size: 14px;
    font-weight: bold;

    @include sp() {
      font-size: 12px;
    }
  }

  &__member-text {
    font-size: 16px;

    @include sp() {
      font-size: 13px;
    }
  }

  &__section {
    &:nth-of-type(n + 2) {
      margin-top: 80px;
    }
  }

  &__section-title {
    @include en-font();
    font-size: 96px;
    font-weight: bold;
    color: #ddd;
    text-align: center;
    position: relative;
    margin-bottom: 20px;

    @include sp() {
      font-size: 44px;
    }
  }

  &__section-sub-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 32px;
    color: #000717;
    z-index: 1;

    @include sp() {
      font-size: 22px;
    }
  }

  &__section-text {
    font-size: 16px;

    @include sp() {
      font-size: 14px;
    }
  }

  &__2column {
    @include pc() {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__2column-item {
    @include pc() {
      width: 48%;

      &:nth-of-type(n + 3) {
        margin-top: 40px;
      }
    }

    @include sp() {
      &:nth-of-type(n + 2) {
        margin-top: 40px;
      }
    }
  }

  &__4column {
    @include pc() {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__4column-item {
    display: block;
    text-decoration: none;

    @include pc() {
      width: 21.73%;
    }

    @include sp() {
      &:nth-of-type(n + 2) {
        margin-top: 40px;
      }
    }
  }

  &__4column-name {
    font-size: 20px;
    font-weight: bold;
  }

  &__4column-post {
    color: $color_primary;
    font-size: 14px;
    font-weight: bold;
  }
}

.process-section {
  max-width: 709px;
  display: block;
  margin: 0 auto;
  width: 100%;
}

.loading-area {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 60px;
    color: #ddd;
  }
}

.not-found-content {
  &__message {
    text-align: center;
    font-size: 18px;

    @include sp() {
      font-size: 16px;
    }
  }

  &__button {
    @include pc() {
      width: 400px;
      margin: 0 auto;
    }
  }
}

.action-interview {
  display: flex;
  justify-content: space-between;

  @include sp() {
    flex-direction: column;
    gap: 15px;
  }

  &__thumbnail {
    @include sp() {
      width: 100%;
      height: auto;
    }
  }

  &__body {
    width: 460px;

    @include sp() {
      width: 100%;
    }
  }

  &__button {
    @include pc() {
      width: 240px;
      padding: 10px 0;
      font-size: 16px;
    }
  }
}

.top-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include sp() {
    gap: 6px;
  }

  &__item {
    @include sp() {
      width: calc(calc(100% / 3) - 4px);
      height: auto;
    }
  }
}

.interview-gradation-title {
  font-size: 32px;
  font-weight: bold;
  background: linear-gradient(268.08deg, #4f20ff 0%, #f7385a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include sp() {
    font-size: 24px;
  }

  &::selection {
    -webkit-text-fill-color: #fff;
  }
}

.service-interview {
  display: flex;
  justify-content: space-between;

  @include sp() {
    flex-direction: column;
    gap: 15px;
  }

  &__thumbnail {
    @include sp() {
      width: 100%;
      height: auto;
    }
  }

  &__body {
    width: 460px;

    @include sp() {
      width: 100%;
    }
  }

  &__button {
    @include pc() {
      width: 240px;
      padding: 10px 0;
      font-size: 16px;
    }

    @include sp() {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding: 10px 0;

    @include sp() {
      gap: 4%;
    }

    &-item {
      margin: 0;
      width: 350px;

      @include pc() {
        width: 240px;
        padding: 10px 0;
        font-size: 16px;
      }

      @include sp() {
        padding: 10px 0;
        width: 48%;
      }
    }

    &-mini {
      @include pc() {
        display: none;
      }

      @include sp() {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px 0;
        gap: 4%;
      }
    }
  }
}

// 2024.10 draw4作業分
@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Oswald:wght@200..700&display=swap');

/* TOP追加{ */

.v2_top .v2_ttl {
  border-bottom: 1px solid #000000;
}

.v2_top .v2_ttl-h2 {
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.v2_top .v2_ttl .basic-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Oswald, Helvetica, Arial, sans-serif;
}

.v2_top .v2_news__list .date {
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.v2_top .v2_news__list .tag_wrap {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.v2_top .v2_news__list .tag {
  display: block;
  color: #999999;
  background: #F3F3F3;
  text-decoration: none;
}

.v2_top .v2_news__list .ttl {
  display: block;
  background: linear-gradient(90deg, #f7385a, #4f20ff 50%, #000717 50%, #000717);
  background-position: 100% 0;
  background-size: 201% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background-position .4s cubic-bezier(.25, 1, .5, 1);
  text-decoration: none;
  color: #000000;
}

.v2_top .v2_news__list .ttl:hover {
  background-position: -1px 0;
}

.v2_top .v2_news__list .ttl .far {
  vertical-align: text-top;
}

.v2_top #video iframe {
  vertical-align: top;
}

.v2_top .v2_portfolio {
  display: grid;
}

.v2_top .basic-button {
  font-family: Oswald, Helvetica, Arial, sans-serif;
}

.bottom_team .basic-button {
  background-image: linear-gradient(90deg, #f7385a, #4f20ff 50%, transparent 50%, transparent)
}

.bottom_team .top-contents-box__view_all_button {
  margin-top: 0;
}

.bottom_team {
  background: url("../img/top/team_bg.jpg") no-repeat center top/cover;
}

.breadcrumbs {
  flex-wrap: nowrap;
}

.breadcrumbs_ttl {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@media (min-width: 769px) {
  .elm_sp {
    display: none;
  }

  .v2_top .top-main-visual {
    height: 560px;
  }

  .v2_top .top_wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: calc(100% / 1040 * 80);
    max-width: 1040px;
    margin: 48px auto;
    padding: 0 0 20px;
    border-bottom: 1px solid #DDDDDD;
  }

  .v2_top .top_wrap>* {
    width: calc(100% / 1040 * 480);
  }

  .v2_top .v2_ttl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 49px;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  .v2_top .v2_ttl-h2 {
    font-size: 28px;
    line-height: 1.0;
  }

  .v2_top .v2_ttl .top-contents-box__view_all_button {
    max-width: 124px;
    width: 100%;
    margin: 0;
  }

  .v2_top .v2_ttl .basic-button {
    font-size: 14px;
    height: 36px;
    padding: 0;
  }

  .v2_top .v2_news__list {
    display: flex;
    flex-flow: column;
    gap: 10px;
  }

  .v2_top .v2_news__list .meta {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .v2_top .v2_news__list .date {
    font-size: 20px;
    line-height: 1.0;
  }

  .v2_top .v2_news__list .tag {
    font-size: 14px;
    line-height: 1.0;
    padding: 2px 4px;
  }

  .v2_top .v2_news__list .ttl {
    font-size: 18px;
    line-height: 1.6;
    margin-top: 6px;
  }

  .v2_top .v2_news__list .ttl .far {
    font-size: 14px;
    line-height: 1.0;
  }

  .v2_top .v2_portfolio {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 28px;
  }

  .v2_top .top-white-area {
    padding-bottom: 60px;
  }

  .v2_top .top-contents-box--under-space {
    border-bottom: none;
    padding-bottom: 50px;
    margin-bottom: 0;
  }

  .v2_top .top-black-area {
    padding-bottom: 0;
  }

  .bottom_team {
    padding: 80px 0;
  }
}

@media (max-width: 768px) {
  .elm_pc {
    display: none;
  }

  .v2_top .top-main-visual {
    height: calc(100vw / 375 * 400);
  }

  .v2_top .top-main-visual__scroll {
    bottom: -30px;
  }

  .v2_top .top_wrap {
    padding: 30px 16px 40px;
  }

  .v2_top .v2_ttl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 49px;
    margin-bottom: 20px;
  }

  .v2_top .v2_ttl-h2 {
    font-size: 28px;
    line-height: 1.0;
  }

  .v2_top #news {
    margin-bottom: 35px;
  }

  .v2_top .v2_news__list {
    display: flex;
    flex-flow: column;
    gap: 10px;
  }

  .v2_top .v2_news__list .meta {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .v2_top .v2_news__list .date {
    font-size: 16px;
    line-height: 1.0;
  }

  .v2_top .v2_news__list .tag {
    font-size: 14px;
    line-height: 1.0;
    padding: 2px 4px;
  }

  .v2_top .v2_news__list .ttl {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 6px;
  }

  .v2_top .v2_news__list .ttl .far {
    font-size: 16px;
    line-height: 1.0;
  }

  .v2_top #video {
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 40px;
  }

  .v2_top #aboutus {
    padding: 0 16px 40px;
  }

  .v2_top .v2_portfolio {
    display: flex;
    flex-flow: column;
    gap: 28px;
    padding: 0 30px;
  }

  .v2_top .top-black-area {
    padding-bottom: 0;
  }

  .v2_top .top-contents-box {
    margin-bottom: 0;
  }

  .v2_top .top-contents-box__view_all_button {
    max-width: 240px;
  }

  .v2_top .basic-button {
    width: 240px;
  }

  .v2_top .basic-button.rev_sp {
    background: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
  }

  .bottom_team {
    padding: 65px 0;
  }

  .bottom_team .basic-button {
    width: 240px;
  }
}

/* } */

/* PROGRAM追加{ */
.program {
  &-inner {
    @include pc() {
      width: 1040px;
      margin: 0 auto;
    }

    @include sp() {
      width: 100%;
      padding: 0 15px;
    }

    .breadcrumbs {
      @include pc() {
        width: 920px;
        margin: 30px auto;
      }
    }

    .contents-header {
      @include pc () {
        width: 920px;
        margin: 0 auto;
      }
    }
  }

  &-top {
    width: 1040px;
    margin: 0 auto;

    @include sp() {
      width: 100vw;
      margin: 0 calc(50% - 50vw);
      padding: 0 8px;
    }
  }

  &-catchword {
    position: relative;
    width: 1030px;
    margin: 0 auto;
    padding-top: 30px;

    @include sp() {
      width: 100%;
      padding-top: 40px;
    }

    &+& {
      margin-top: 50px;

      @include sp() {
        margin-top: 70px;
      }
    }

    &__en-title {
      line-height: 1;
      @include en-font();
      position: absolute;
      top: 25px;
      right: 0;
      color: #ddd;
      font-size: 64px;
      letter-spacing: 0.03em;

      @include sp() {
        top: -13px;
        font-size: 96px;
        letter-spacing: 0;
        right: 3px;
      }
    }

    &__title {
      position: relative;
      font-size: 48px;
      letter-spacing: 0.2em;
      margin-bottom: 25px;
      line-height: 1.2;

      @include sp() {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }

    &__lead {
      position: relative;
      font-size: 20px;
      letter-spacing: 0.05em;

      @include sp() {
        font-size: 14px;
      }
    }
  }

  &-contents {
    @include pc() {
      width: 1040px;
      margin: 0 auto;
    }

    &#process {
      @include pc() {
        border-bottom: 1px solid #ddd;
      }
    }

    &__title {
      font-size: 68px;
      line-height: 1;
      @include en-font();
      color: #ddd;

      @include pc() {
        padding: 0 0 0 30px;
        font-size: 100px;
        letter-spacing: 0.03em;
      }
    }

    &__box {
      @include pc() {
        width: 920px;
        margin: 0 auto;
      }
    }

    &__img {
      display: block;
      margin: 0 auto;
      width: 100%;

      @include pc() {
        max-width: 920px;
      }
    }

    &__text {
      font-size: 16px;

      @include sp() {
        font-size: 14px;
      }
    }

    &__wrap {
      border-top: 1px solid #ddd;

      @include pc() {
        width: 920px;
        margin: 0 auto;
      }
    }

    &__subtitle {
      line-height: 1.5;
      font-family: Oswald, Helvetica, Arial, sans-serif;
      font-weight: 600;
      font-size: 44px;
      color: #ddd;

      @include pc() {
        font-size: 64px;
        letter-spacing: .03em;
      }
    }

    &__heading {
      font-family: 'Sofia Sans';
      font-size: 24px;
      font-weight: 800;

      @include sp() {
        font-size: 20px;
        line-height: 1.2;
      }

      span {
        font-style: italic;
        font-size: 24px;
        font-weight: 800;
      }
    }
  }
}

/* } */

/* PORTFOLIO追加{ */
.v2_portfolio .v2_ttl {
  border-bottom: 1px solid #000000;
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.v2_portfolio .border-title {
  border-bottom-color: #DDD;
}

.works-card.v2 .basic-button {
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.works-card.v2 .basic-button::after {
  font-weight: 600;
}

.v2_portfolio .info .box h3 {
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.v2_portfolio .info .read {
  border-bottom: 1px solid #ddd;
}

.v2_portfolio .info .url a {
  color: inherit;
  font-style: italic;
}

.v2_news-list .date {
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

.v2_news-list .ttl {
  color: inherit;
}

.v2_portfolio .info_wrap .works-card__thumbnail::after {
  display: none;
}

@media (min-width: 769px) {
  .v2_portfolio .v2_ttl {
    font-size: 28px;
    line-height: 1.0;
    padding-bottom: 20px;
    margin-bottom: 35px;
  }

  .v2_portfolio .list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  .works-card.v2 {
    flex-flow: column;
    position: relative;
    margin-top: 0 !important;
  }

  .v2_portfolio:not(.top) .works-card.v2 {
    width: calc(100% / 920 * 440);
  }

  .works-card.v2 .works-card__thumbnail {
    width: 100%;
    height: 220px;
    position: static;
  }

  .works-card.v2 .works-card__thumbnail-image {
    width: 100%;
    height: 220px;
  }

  .v2_portfolio:not(.top) .works-card.v2 .works-card__thumbnail-box {
    width: calc(100% / 440 * 266);
  }

  .works-card.v2 .works-card__thumbnail-lead {
    font-family: "ABeeZee", Arial, Roboto, Droid Sans, "游ゴシック Medium", Yu Gothic Medium, "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, "メイリオ", Meiryo, sans-serif;
    font-size: 14px;
    line-height: 1.8;
    font-style: italic;
  }

  .works-card.v2 .basic-button {
    padding: 9px 15px;
  }

  .works-card.v2 .works-card__body {
    padding: 30px;
  }

  .v2_portfolio:not(.top) .works-card.v2 .works-card__body {
    width: calc(100% / 440 * 256);
    max-width: 256px;
    padding: 30px;
  }

  .v2_portfolio.top .works-card.v2 .works-card__body {
    width: 256px;
  }

  .v2_portfolio .info_wrap {
    display: flex;
    justify-content: space-between;
  }

  .v2_portfolio #info {
    margin-bottom: 40px;
  }

  .v2_portfolio .info {
    width: calc(100% / 920 * 445);
  }

  .v2_portfolio .info .read {
    font-size: 18px;
    line-height: 1.5;
    padding-bottom: 10px;
    margin-bottom: 25px;
  }

  .v2_portfolio .info .box h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .v2_portfolio .info .box p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .v2_news-list {
    display: flex;
    flex-flow: column;
    gap: 20px;
    margin-bottom: 30px;
  }

  .v2_news-list .date {
    font-size: 20px;
    line-height: 1.0;
  }

  .v2_news-list .ttl {
    font-size: 18px;
    line-height: 1.0;
  }

  .v2_portfolio.detail {
    padding-bottom: 130px;
  }
}

@media (max-width: 768px) {
  .v2_portfolio .v2_ttl {
    font-size: 20px;
    line-height: 1.0;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .v2_portfolio:not(.top) .works-card.v2 {
    margin: 0 28px;
  }

  .v2_portfolio:not(.top) .works-card.v2:not(:last-child) {
    margin-bottom: 28px;
  }

  .works-card.v2 .works-card__body {
    padding: 20px;
  }

  .works-card.v2 .works-card__logo {
    width: calc(100% / 288 * 256);
    max-width: 256px;
  }

  .works-card.v2 .works-card__body-button {
    margin-top: 20px;
  }

  .works-card.v2 .basic-button {
    font-size: 16px;
  }

  .v2_portfolio .info_wrap {
    margin: 0 16px;
  }

  .v2_portfolio .info {
    padding-bottom: 20px;
  }

  .v2_portfolio .info .read {
    font-size: 14px;
    line-height: 1.5;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .v2_portfolio .info .box {
    padding: 0 28px;
  }

  .v2_portfolio .info .box h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .v2_portfolio .info .box p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  .v2_news-list {
    display: flex;
    flex-flow: column;
    gap: 20px;
    margin-bottom: 40px;
  }

  .v2_news-list .date {
    font-size: 16px;
    line-height: 1.0;
  }

  .v2_news-list .ttl {
    font-size: 16px;
    line-height: 1.4;
  }

}

/* } */

.v2_team .border-title {
  border-bottom-color: #000717;
}

.v2_team .item .name h3 {
  font-weight: 400;
}

.v2_team .item .name .sta_1 {
  color: #FD4477;
}

.v2_team .item .name .sta_2 {
  color: #666666;
}

.v2_team a.item {
  color: inherit;
  text-decoration: none;
  transition: all 300ms 0s ease;
}

.v2_team a.item:hover {
  opacity: 0.6;
}

.v2_team a.item:hover figure img {
  transform: scale(1.2);
}

.v2_team .item figure {
  overflow: hidden;
}

.v2_team .item figure img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  transition: all 300ms 0s ease;
}

.v2_team .item .sns {
  display: flex;
  justify-content: center;
  ;
  border-top: 1px solid #DDDDDD;
}

.v2_team .item .sns a {
  display: block;
  background-image: linear-gradient(90deg, #f7385a, #4f20ff 50%, #000000 50%, #000000);
  background-position: 100% 0;
  background-size: 201% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #000000;
  text-decoration: none;
  transition: background-position .4s cubic-bezier(.25, 1, .5, 1);
  padding: 1px;
}

.v2_team .item .sns a:hover {
  background-position: -1px 0;
}


.v2_team .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 42px;
}

.v2_team .modal .over {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  transition: background 300ms 0s ease;
}

.v2_team .modal .item_wrap {
  background: #FFFFFF;
  position: relative;
  z-index: 2;
}

.v2_team .modal .item {
  max-height: calc(100vh - 52px);
  overflow-y: auto;
}

.v2_team .modal .item .name {
  text-align: center;
}

.v2_team .modal .item .name .sta {
  justify-content: center;
}

.v2_team .modal .close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  top: -32px;
  background: #000000;
  color: #FFFFFF;
  font-size: 28px;
  line-height: 1.0;
  cursor: pointer;
}

.v2_team .loading-area {
  height: 100px;
  width: 100%;
}

.v2_team .modal.off .over {
  background: rgba(0, 0, 0, 0);
}

.scroll_stop {
  overflow: hidden;
}

/* TEAM追加{ */
@media (min-width: 769px) {
  .v2_team #owner {
    margin-bottom: 100px;
  }

  .v2_team .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }

  .v2_team #owner .list {
    grid-template-columns: 1fr 1fr;
  }

  .v2_team .item figure {
    margin-bottom: 10px;
  }

  .v2_team .item .name i {
    display: none;
  }

  .v2_team .item .name h3 {
    font-size: 20px;
    line-height: 1.5;
  }

  .v2_team .item .name .sta {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 1.5;
  }

  .v2_team .item .text {
    font-size: 16px;
    line-height: 1.8;
    margin-top: 15px;
  }

  .v2_team .item .sns {
    padding: 50px 0 40px;
    margin-top: 30px;
    gap: 20px;
  }

  .v2_team .item .sns a {
    font-size: 30px;
  }

  .v2_team .modal .item {
    width: 460px;
    padding: 30px;
  }

  .v2_team .modal .loading-area {
    height: 460px;
    width: 460px;
  }
}

@media (max-width: 768px) {
  .v2_team #owner {
    margin-bottom: 40px;
  }

  .v2_team .list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .v2_team .item figure {
    margin-bottom: 20px;
  }

  .v2_team .item .name h3 {
    font-size: 20px;
    line-height: 1.5;
  }

  .v2_team .item .name .sta {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 1.5;
  }

  .v2_team .item .text {
    font-size: 14px;
    line-height: 1.6;
    margin-top: 15px;
  }

  .v2_team a.item .name {
    position: relative;
  }

  .v2_team .item .name i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid #030A1A;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1;
  }

  .v2_team .item .sns {
    padding: 40px 0 28px;
    margin-top: 20px;
    gap: 20px;
  }

  .v2_team .item .sns a {
    font-size: 30px;
  }

  .v2_team .modal .item {
    max-width: 100vw;
    width: 360px;
    padding: 12px;
  }

  .v2_team .modal .loading-area {
    max-height: 100vh;
    height: 360px;
    width: 360px;
  }
}

/* } */

/* NEWS追加{ */
.v2_news .news-selector__wrap {
  display: flex;
  flex-flow: row-reverse;
}

.news-selector__list.is-shown {
  z-index: 1;
}

@media (min-width: 769px) {
  .v2_news .news-selector {
    margin: 0 0 40px 20px;
  }
}

@media (max-width: 768px) {
  .v2_news .news-selector {
    margin: 0 0 40px 20px;
  }
}

/* } */

.no_post {
  width: 100%;
  font-size: 15px;
}

.no_post .btns {
  margin-top: 15px;
}

.no_post .btns .basic-button {
  font-family: Oswald, Helvetica, Arial, sans-serif;
  width: fit-content;
  margin: 0 auto;
  line-height: 1;
}