/* SP専用クラス */
@include sp(){
  @for $i from 0 through 20{
    .u-mt#{($i * 5)}sp{ margin-top: #{($i * 5)}px !important; }
    .u-mr#{($i * 5)}sp{ margin-right: #{($i * 5)}px !important; }
    .u-mb#{($i * 5)}sp{ margin-bottom: #{($i * 5)}px !important; }
    .u-ml#{($i * 5)}sp{ margin-left: #{($i * 5)}px !important; }
    .u-pt#{($i * 5)}sp{ padding-top: #{($i * 5)}px !important; }
    .u-pr#{($i * 5)}sp{ padding-right: #{($i * 5)}px !important; }
    .u-pb#{($i * 5)}sp{ padding-bottom: #{($i * 5)}px !important; }
    .u-pl#{($i * 5)}sp{ padding-left: #{($i * 5)}px !important; }
  }
  .u-spHide{ display: none !important; }
};

/* PC専用クラス */
@include pc(){
  @for $i from 0 through 20{
    .u-mt#{($i * 5)}pc{ margin-top: #{($i * 5)}px !important; }
    .u-mr#{($i * 5)}pc{ margin-right: #{($i * 5)}px !important; }
    .u-mb#{($i * 5)}pc{ margin-bottom: #{($i * 5)}px !important; }
    .u-ml#{($i * 5)}pc{ margin-left: #{($i * 5)}px !important; }
    .u-pt#{($i * 5)}pc{ padding-top: #{($i * 5)}px !important; }
    .u-pr#{($i * 5)}pc{ padding-right: #{($i * 5)}px !important; }
    .u-pb#{($i * 5)}pc{ padding-bottom: #{($i * 5)}px !important; }
    .u-pl#{($i * 5)}pc{ padding-left: #{($i * 5)}px !important; }
  }
  .u-pcHide{ display: none !important; }
};

/* 全デバイス共通 */
@for $i from 0 through 20{
  .u-mt#{($i * 5)}{ margin-top: #{($i * 5)}px !important; }
  .u-mr#{($i * 5)}{ margin-right: #{($i * 5)}px !important; }
  .u-mb#{($i * 5)}{ margin-bottom: #{($i * 5)}px !important; }
  .u-ml#{($i * 5)}{ margin-left: #{($i * 5)}px !important; }
  .u-pt#{($i * 5)}{ padding-top: #{($i * 5)}px !important; }
  .u-pr#{($i * 5)}{ padding-right: #{($i * 5)}px !important; }
  .u-pb#{($i * 5)}{ padding-bottom: #{($i * 5)}px !important; }
  .u-pl#{($i * 5)}{ padding-left: #{($i * 5)}px !important; }
}
@for $i from 10 through 50{
  .u-fz#{$i}{ font-size: #{$i}px !important;}
}
.u-textRight{ text-align: right !important; }
.u-textCenter{ text-align: center !important; }
.u-textLeft{ text-align: left !important; }
.u-vaBase{ vertical-align: baseline !important; }
.u-vaTop{ vertical-align: top !important; }
.u-vaMiddle{ vertical-align: middle !important; }
.u-vaBottom{ vertical-align: bottom !important; }
.u-vaTextTop{ vertical-align: text-top !important; }
.u-vaTextBottom{ vertical-align: text-bottom !important; }
.u-vaSuper{ vertical-align: super !important; }
.u-vaSub{ vertical-align: sub !important; }
.u-block{ display: block !important; }
.u-inline{ display: inline !important; }
.u-inlineBlock{ display: inline-block !important; }
.u-flex{ display: flex !important; }
.u-fxdRow { flex-direction: row !important; }
.u-fxdRowRev { flex-direction: row-reverse !important; }
.u-fxdCol { flex-direction: column !important; }
.u-fxdColRev { flex-direction: column-reverse !important; }
.u-fxwNo { flex-wrap: nowrap !important; }
.u-fxwWrap { flex-wrap: wrap !important; }
.u-fxwWrapRev { flex-wrap: wrap-reverse !important; }
.u-jcFlexStart { justify-content: flex-start !important; }
.u-jcFlexEnd { justify-content: flex-end !important; }
.u-jcCenter { justify-content: center !important; }
.u-jcSpaceBetween { justify-content: space-between !important; }
.u-jcSpaceAround { justify-content: space-around !important; }
.u-aiFlexStart { align-items: flex-start !important; }
.u-aiFlexEnd { align-items: flex-end !important; }
.u-aiCenter { align-items: center !important; }
.u-aiBase { align-items: baseline !important; }
.u-aiStretch { align-items: stretch !important; }
.u-acFlexStart { align-content: flex-start !important; }
.u-acFlexEnd { align-content: flex-end !important; }
.u-acCenter { align-content: center !important; }
.u-acSpaceBetween { align-content: space-between !important; }
.u-acSpaceAround { align-content: space-around !important; }
.u-acStretch { align-content: stretch !important; }
.u-asAuto { align-self: auto !important; }
.u-asFlexStart { align-self: flex-start !important; }
.u-asFlexEnd { align-self: flex-end !important; }
.u-asCenter { align-self: center !important; }
.u-asBase { align-self: baseline !important; }
.u-asStretch { align-self: stretch !important; }
.u-floatLeft{ float: left !important; }
.u-floatRight{ float: right !important; }
.u-static{ position: static !important; }
.u-relative{ position: relative !important; }
.u-absolute{ position: absolute !important; }
.u-bgNone{ background: none !important; }
.u-fwBold{ font-weight: bold !important; }
.u-fwNormal{ font-weight: normal !important; }
.u-textKerning{ font-feature-settings: "palt" !important; }
.u-clearfix:after{
  content: "" !important;
  display: block !important;
  clear: both !important;
}
.u-wh100p{
  width: 100% !important;
  height: auto !important;
}
.u-maxWh100p{
  max-width: 100% !important;
  height: auto !important;
}
.u-marginSideAuto{
	margin-left: auto !important;
	margin-right: auto !important;
}
.u-hover:hover{
  @include pc() {
    opacity: .7 !important;
    transition: opacity 250ms !important;
  }
}

.u-clearBorder {
  border: 0 !important;
}

.u-hidden {
  display: none !important;
}

.u-color-blue {
  color: #58bdec !important;
}

.u-lh2 {
  line-height: 2 !important;
}

.u-colorPink {
  color: #f7385a !important;
}